module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"

  siteTitle: 'My Favorite Bridge', // Navigation and Site Title
  siteTitleAlt: 'My Favorite Bridge', // Alternative Site title for SEO
  siteTitleShort: 'Emilia', // short_name for manifest
  siteHeadline: 'I love the Zakim', // Headline for schema.org JSONLD
  siteUrl: 'https://kylesfavoritebridge.com', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '/logos/logo.png', // Used for SEO and manifest
  siteDescription: 'All about my favorite bridge',
  author: 'LekoArts', // Author for schema.org JSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
//  userTwitter: '@emilia', // Twitter Username
//  ogSiteName: 'emilia', // Facebook Site Name
//  ogLanguage: 'en_US', // og:language
//  googleAnalyticsID: 'UA-47519312-4',

  // Manifest and Progress color
  themeColor: '#3498DB',
  backgroundColor: '#2b2e3c',

  // Your information
  avatar: '/logos/lekoarts.png',
  name: 'Kyle Barber',
  location: 'Zakim Bridge Lover',
  socialMedia: [
    {
      url: 'https://www.instagram.com/mrbarber22',
      name: 'Instagram',
    },
    {
      url: 'http://www.hudl.com/profile/670366/Kyle-Barber',
      name: 'Hudl',
    },
  ],
}
